<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem v-if="type === 'detail'">订单查看</BreadcrumbItem>
        <BreadcrumbItem v-if="type === 'examine'">订单审核</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              {{ orderInfo.orderSn }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="客户">
              {{ orderInfo.orderCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商家">
              {{ orderInfo.producerCorporationName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单类别">
              {{ orderInfo.orderTypeValue }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="申请时间">
              {{ orderInfo.createTime ? tsFormat(orderInfo.createTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="订单状态"
              v-if="
                fromRouterName === 'OperationReturnedOrderList' ||
                  storeRouterName === 'OperationReturnedOrderList'
              "
            >
              {{ orderInfo.nodeStatus }}
            </a-form-item>
            <a-form-item label="审核状态" v-else>
              <span
                class="status--orange"
                v-if="orderInfo.orderStatusValue === '待审核'"
              >
                {{ orderInfo.orderStatusValue }}
              </span>
              <span v-else>{{ orderInfo.orderStatusValue }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="原订单号">
              <a>
                <span @click="onCheckPurchaseOrder(orderInfo)">
                  {{ orderInfo.orderPurchaseSn }}
                </span>
              </a>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">收货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              {{ orderInfo.consigneeRealName || "--" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              {{ orderInfo.consigneeTel || "--" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址">
              {{ orderInfo.consigneeAddress || "--" }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商品信息</div>
      <a-table
        class="commodity__table"
        :data-source="commodityData"
        :columns="commodityColumns"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      ></a-table>
    </section>
    <section class="card__container">
      <div class="container__title">申请信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="18">
            <a-form-item label="备注">
              {{ orderInfo.orderMemo }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="照片">
              <div class="pic__container" v-viewer>
                <div v-for="(item, index) in orderInfo.files" :key="index">
                  <img
                    :data-source="imageBaseUrl + item.fileUrl"
                    :src="imageBaseUrl + item.fileUrl"
                    :alt="item.fileName"
                  />
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container" v-if="status !== 'approval'">
      <div class="container__title">合同信息</div>
      <a-table
        class="agreement__table"
        :columns="agreementColumns"
        :data-source="agreementData"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="signTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            :href="imageBaseUrl + record.contractFile.fileUrl"
            v-if="record.contractFile"
            target="_blank"
          >
            预览
          </a>
          <span v-else>-</span>
        </template>
      </a-table>
    </section>
    <section class="card__container" v-if="saleBillNum > 0">
      <div class="container__title">
        物流信息
        <span v-if="saleBillNum > 0">（{{ saleBillNum }}）条</span>
        <a
          class="check__all"
          v-if="saleBillNum > 1"
          @click="onCheckDeliverDetail"
        >
          查看全部
        </a>
      </div>
      <div class="sale__title">第一批明细：</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="发货时间">
              {{
                saleBillInfo.logisticsSendTime
                  ? tsFormat(saleBillInfo.logisticsSendTime)
                  : "-"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品金额">
              {{ calculatePrice(inventoryData) }} 元
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="物流公司">
              {{
                saleBillInfo.logisticsName ? saleBillInfo.logisticsName : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="物流单号">
              {{ saleBillInfo.logisticsSn ? saleBillInfo.logisticsSn : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="物流电话">
              {{ saleBillInfo.logisticsTel ? saleBillInfo.logisticsTel : "-" }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="发货清单">
              <a-table
                :columns="inventoryColumns"
                :data-source="inventoryData"
                :loading="inventoryLoading"
                :pagination="false"
                :rowKey="(record, index) => index"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="receiveBill && receiveBill.length > 0">
          <div v-for="item in receiveBill" :key="item.receivingId">
            <a-row>
              <a-col :span="8">
                <a-form-item label="收货时间">
                  {{ item.signTime ? tsFormat(item.signTime) : "-" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="发票信息">
                  <a>查看</a>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="商品金额">
                  {{ calculatePrice(item.deliverVoList) }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="收货清单">
                  <a-table
                    :columns="inventoryColumns"
                    :data-source="item.deliverVoList"
                    :pagination="false"
                    :rowKey="(record, index) => index"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-form>
    </section>
    <section
      class="card__container"
      :class="type === 'examine' ? 'high__bottom' : 'low__bottom'"
    >
      <div class="container__title between">
        流程日志
        <div class="process__img">
          <a @click="onShowProcessImg">点击查看流程图</a>
        </div>
        <div v-viewer class="images">
          <img
            v-if="
              (orderInfo.purchaserCorporationDistributorTypeValue === '货栈' ||
                orderInfo.purchaserCorporationDistributorTypeValue ===
                  '服务中心') &&
                orderInfo.producerCorporationDistributorTypeValue === '原厂库'
            "
            src="../../../assets/imgs/process/returnedOrder.png"
            :data-source="
              require('../../../assets/imgs/process/returnedOrder.png')
            "
            alt="流程图"
          />
          <img
            v-if="
              orderInfo.purchaserCorporationDistributorTypeValue ===
                '渠道终端' &&
                orderInfo.producerCorporationDistributorTypeValue === '原厂库'
            "
            src="../../../assets/imgs/process/return-end-factory.png"
            :data-source="
              require('../../../assets/imgs/process/return-end-factory.png')
            "
            alt="流程图"
          />
          <img
            v-if="
              orderInfo.purchaserCorporationDistributorTypeValue ===
                '渠道终端' &&
                (orderInfo.producerCorporationDistributorTypeValue === '货栈' ||
                  orderInfo.producerCorporationDistributorTypeValue ===
                    '服务中心')
            "
            src="../../../assets/imgs/process/return-end-service.png"
            :data-source="
              require('../../../assets/imgs/process/return-end-service.png')
            "
            alt="流程图"
          />
        </div>
      </div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="reason" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
      </a-table>
    </section>
    <section class="examine__container" v-if="type === 'examine'">
      <a-form class="examine__form">
        <a-row>
          <a-col :span="24">
            <a-form-item label="订单审核意见">
              <a-textarea
                :auto-size="{ minRows: 1, maxRows: 2 }"
                v-model="approvalMemo"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="tip">
        <a-icon type="info-circle" />
        审核不通过，审核意见必填
      </div>
      <div class="operation">
        <a-space>
          <a-button @click="onReject">不通过</a-button>
          <a-button type="primary" @click="onPass" :loading="passLoading">
            通过
          </a-button>
        </a-space>
      </div>
    </section>
  </a-layout>
</template>

<script>
import { tsFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import {
  fetchOrderDetail,
  fetchOrderLog,
  fetchPayResult,
  orderExamine
} from "@/services/OrderManageService";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import {
  fetchReceiveBillBySaleBill,
  fetchSaleBillByOrderSn
} from "@/services/OrderService";

const commodityColumns = [
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "20%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "30%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "10%"
  },
  {
    title: "采购单价（元）",
    dataIndex: "skuPurchasePrice",
    width: "10%"
  },
  {
    title: "退货数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "10%"
  },
  {
    title: "采购总价（元）",
    dataIndex: "skuPurchaseTotalPrice",
    width: "10%"
  }
];

const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "20%"
  },
  {
    title: "所属渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "15%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];
const inventoryColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "40%"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品序列号",
    dataIndex: "goodsSn",
    width: "15%"
  },
  {
    title: "商品单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "商品数量",
    dataIndex: "quantity",
    width: "15%"
  }
];
const flowColumns = [
  {
    title: "流水号",
    dataIndex: "huifuSeqId",
    scopedSlots: { customRender: "huifuSeqId" },
    width: "15%"
  },
  {
    title: "退款机构",
    dataIndex: "paymentCorporationName",
    width: "20%"
  },
  {
    title: "收款机构",
    dataIndex: "receiveCorporationName",
    width: "20%"
  },
  {
    title: "应退款金额（元）",
    dataIndex: "actualPayment",
    width: "10%"
  },
  // {
  //   title: "实际退款金额（元）",
  //   dataIndex: "accountPaid",
  //   width: "10%"
  // },
  {
    title: "退款时间",
    dataIndex: "paymentTime",
    scopedSlots: { customRender: "paymentTime" },
    width: "15%"
  },
  {
    title: "状态",
    dataIndex: "paymentStatus",
    width: "10%"
  }
];
const agreementColumns = [
  {
    title: "签署方1",
    dataIndex: "partyFirst",
    width: "20%"
  },
  {
    title: "签署方2",
    dataIndex: "partySecond",
    width: "20%"
  },
  {
    title: "合同名称",
    dataIndex: "contractName",
    width: "30%"
  },
  {
    title: "签订时间",
    dataIndex: "signTime",
    scopedSlots: { customRender: "signTime" },
    width: "20%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];

export default {
  name: "OperationReturnedOrderDetail",
  components: {
    Breadcrumb,
    BreadcrumbItem
  },
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      commodityData: [],
      commodityColumns,
      loading: false,
      logColumns,
      logData: [],
      logLoading: false,
      orderInfo: {},
      tsFormat,
      approvalMemo: "",
      imageBaseUrl,
      passLoading: false,
      fromRouterName: "",
      storeRouterName: "",
      saleBillInfo: {},
      saleBillNum: 0,
      inventoryColumns,
      inventoryData: [],
      inventoryLoading: false,
      receiveBill: [],
      flowColumns,
      flowList: [],
      flowLoading: false,
      status: this.$route.params.status,
      agreementColumns,
      agreementData: []
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    this.loadDetail(this.status);
    this.loadLogList();
    this.loadSaleBill();
    this.loadPayBill();
  },
  methods: {
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    // 加载详情
    loadDetail(status) {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: status
      };
      fetchOrderDetail(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.orderInfo = resp.data.data;
          this.commodityData = this.orderInfo.instantOrderSkus;
          if (this.commodityData && this.commodityData.length > 0) {
            let num = 0;
            let totalPrice = 0;
            let factoryPrice = 0;
            this.commodityData.forEach(item => {
              num = num + parseInt(item.purchaseQuantity);
              factoryPrice =
                factoryPrice + parseFloat(item.skuFactoryTotalPrice);
              totalPrice = totalPrice + parseFloat(item.skuPurchaseTotalPrice);
            });
            const params = {
              skuCode: "合计",
              purchaseQuantity: num,
              skuFactoryTotalPrice: factoryPrice.toFixed(2),
              skuPurchaseTotalPrice: totalPrice.toFixed(2)
            };
            this.commodityData.push(params);
          }
          this.agreementData =
            this.orderInfo.contracts && this.orderInfo.contracts.length > 0
              ? this.orderInfo.contracts
              : [];
        }
      });
    },
    // 查看采购订单
    onCheckPurchaseOrder(orderInfo) {
      let orderSn = orderInfo.orderPurchaseSn;
      const str = orderSn.substring(0, 2);
      if (str === "YQ") {
        const routeData = this.$router.resolve({
          name: "ForwardPurchaseSubOrder",
          params: { orderSn: orderSn, status: "payment-approval" }
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderDetailOperation",
          params: {
            orderSn: orderSn,
            type: "detail",
            status: "payment-approval"
          }
        });
        window.open(routeData.href, "_blank");
      }
    },
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(this.orderSn, "").then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 不通过
    onReject() {
      if (!this.approvalMemo) {
        this.$message.info("请输入订单审核意见");
        return;
      }
      const params = {
        orderSn: this.orderSn,
        isAdopt: false,
        approvalMemo: this.approvalMemo
      };
      orderExamine(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.$router.go(-1);
        }
      });
    },
    // 通过
    onPass() {
      this.passLoading = true;
      const params = {
        orderSn: this.orderSn,
        isAdopt: true,
        approvalMemo: this.approvalMemo
      };
      orderExamine(params).then(resp => {
        this.passLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          this.$router.go(-1);
        }
      });
    },
    // 获取发货单
    loadSaleBill() {
      this.inventoryLoading = true;
      fetchSaleBillByOrderSn(this.orderSn)
        .then(resp => {
          this.inventoryLoading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              let list = data.reverse();
              this.saleBillNum = list.length;
              this.saleBillInfo = list[0];
              this.inventoryData = this.saleBillInfo.detailVoList;
              this.loadReceiveBill(this.saleBillInfo.consignmentId);
            }
          }
        })
        .catch(() => {
          this.inventoryLoading = false;
        });
    },
    // 获取收货单
    loadReceiveBill(consignmentId) {
      fetchReceiveBillBySaleBill(consignmentId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.receiveBill = resp.data.data;
        }
      });
    },
    // 计算商品金额
    calculatePrice(list) {
      let num = 0;
      if (list && list.length > 0) {
        list.forEach(item => {
          num = num + item.skuFactoryPrice * item.quantity;
        });
        num = num.toFixed(2);
      } else {
        num = "-";
      }
      return num;
    },
    // 查看全部物流
    onCheckDeliverDetail() {
      this.$router.push({
        name: "DeliverDetail",
        params: { orderSn: this.orderSn }
      });
    },
    // 查付款单
    loadPayBill() {
      this.flowLoading = true;
      fetchPayResult(this.orderSn)
        .then(resp => {
          this.flowLoading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              this.flowList = data;
            }
          }
        })
        .catch(() => {
          this.flowLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.tip {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.top {
  margin-top: 10px;
}

.price__item {
  width: 70%;
  min-width: 1080px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(164, 173, 179, 0.6);
  box-sizing: border-box;
  padding: 13px 10px;
  margin-top: 20px;
}

.price__item--value {
  flex: 1;
  display: flex;
  margin: 0 10%;
}

.label {
  min-width: 100px;
}

.right {
  margin-right: 26%;
}

.left {
  margin-left: 26%;
}

.commodity__table {
  margin-top: 20px;
}

.between {
  display: flex;
  justify-content: space-between;
}

.images img {
  display: none;
}

.process__img {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-weight: unset;
}

.high__bottom {
  margin-bottom: 150px;
}

.low__bottom {
  margin-bottom: 30px;
}

.examine__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 20px 20px 220px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.examine__form /deep/ .ant-form-item {
  display: flex;
  margin: 0 40px 0 0;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.top {
  margin-top: 10px;
}

.operation {
  display: flex;
  justify-content: flex-end;
}

.log__table {
  margin-top: 20px;
}

.status--orange {
  color: rgba(255, 134, 0, 1);
}

.radio__item {
  margin-bottom: 10px;
}

.address__container {
  margin-top: 20px;
}

.pic__container {
  display: flex;
}

.pic__container img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}

.sale__title {
  margin-top: 1rem;
}

.flow__table {
  margin-top: 20px;
}

.agreement__table {
  margin-top: 20px;
}
</style>
